enum Subject {
  All = 'all',

  // MongoDB Resources
  AllowedEmailDomain = 'AllowedEmailDomain',
  Content = 'ContentV2',
  CTA = 'CTA',
  EthereumAddressIdentity = 'EthereumAddressIdentity',
  Metaverse = 'Metaverse',
  MetaverseCustomFields = 'MetaverseCustomFields',
  MetaverseVisitor = 'MetaverseVisitor',
  Feature = 'Feature',
  GameLiftRoom = 'GameLiftRoom',
  ImageSlot = 'ImageSlot',
  Invite = 'Invite',
  Metric = 'Metric',
  Environment = 'Environment',
  OpenIDConnectIdentity = 'OpenIDConnectIdentity',
  Organization = 'Organization',
  OrganizationInvite = 'OrganizationInvite',
  OrganizationMember = 'OrganizationMember',
  Page = 'Page',
  PasswordChangeRequest = 'PasswordChangeRequest',
  PasswordIdentity = 'PasswordIdentity',
  PureWebConfiguration = 'PurewebConfiguration',
  RefreshToken = 'RefreshToken',
  Room = 'Room',
  RoomMessage = 'RoomMessage',
  RoomParticipant = 'RoomParticipant',
  Showcase = 'Showcase',
  ShowcaseContent = 'ShowcaseContent',
  Space = 'Space',
  UnrealSession = 'UnrealSession',
  User = 'User',
  UserAction = 'UserAction',
  UserFeatureStatus = 'UserFeatureStatus',
  UserNotification = 'UserNotification',
  WorldMap = 'WorldMap',

  // Other Resources or Actions
  DatabaseManagement = 'DatabaseManagement',
  GameLiftSession = 'GameLiftSession',
  ModerationAction = 'ModerationAction',
  Presentation = 'Presentation',
  TechSupportMessage = 'TechSupportMessage',
  AdditionalPageTemplates = 'AdditionalPageTemplates',
  AdvancedSettings = 'AdvancedSettings',
  AdvancedContentUpload = 'AdvancedContentUpload',
  AdvancedUIControls = 'AdvancedUIControls',
  ChangeEnvironmentOnExistingMetaverse = 'ChangeEnvironmentOnExistingMetaverse',
}

export default Subject;
