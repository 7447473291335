import React, { useEffect, useState } from 'react';
import ModerationAction from '../../../../interfaces/ModerationAction';
import RoomMessage from '../../../../interfaces/RoomMessage';
import ContextMenu, { ContextMenuItem } from '../../../../components/ContextMenu/ContextMenu';
import { ReactComponent as MenuIcon } from '../../../../assets/svg/ellipsis-h.svg';
import avatar from '../../../../assets/images/profile-pic.png';
import styles from './LivestreamChatMessage.module.scss';
import { urlParse, sanitizeText } from '../../../../utils/StringUtils';
import { PlatformRole } from '../../../../interfaces';
import { getDisplayName } from '../../../../utils/DisplayNameUtil';
import useAccessMetaverse from '../../hooks/useAccessMetaverse';
import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';

interface LivestreamChatMessageProps {
  roles: PlatformRole[];
  onContextActionClicked: Function | undefined;
  message: RoomMessage;
}

const usernameColors = [
  '#90C55B',
  '#006CD6',
  '#6546AA',
  '#6B456E',
  '#AE2676',
  '#DD9905',
  '#FE5000',
  '#0D69AF',
];

const LivestreamChatMessage = (
  {
    roles, onContextActionClicked, message,
  }: LivestreamChatMessageProps,
) => {
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [contextActions, setContextActions] = useState<ContextMenuItem[]>([]);
  const { ability } = useAccessMetaverse();

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    let actions: any[] = []; // Add  default actions here
    const salesRepActions: ContextMenuItem[] = [
      {
        label: 'See Interests',
        onClick: () => {
          if (onContextActionClicked) {
            onContextActionClicked(ModerationAction.SeeInterests, message.userId);
          }
          handleDropdownClose();
        },
      },
    ];
    const adminContextActions: ContextMenuItem[] = [
      {
        label: 'Delete',
        onClick: () => {
          if (onContextActionClicked) {
            onContextActionClicked(ModerationAction.DeleteMessage, message._id);
          }
          handleDropdownClose();
        },
      },
    ];
    if (ability.can(Action.Manage, Subject.ModerationAction)) {
      actions = actions.concat(adminContextActions);
    }
    if (roles.includes(PlatformRole.SalesRep)) {
      actions = actions.concat(salesRepActions);
    }
    setContextActions(actions);
  }, [roles]);

  return (
    <div className={styles['livestream-chat-message-bubble']}>
      <div className={styles['chat-data']}>
        <img className={styles.avatar} src={message.user?.picture || avatar} />
        <div className={styles.text}>
          <div className={styles.username} style={{ color: usernameColors[(10 * (getDisplayName(message.user).length)) % usernameColors.length] }}>
            {`${getDisplayName(message.user)}: `}
          </div>
          <div className={styles['message-text']} dangerouslySetInnerHTML={{ __html: urlParse(sanitizeText(message.text)) }} />
        </div>
        {
          contextActions.length > 0 && onContextActionClicked &&
          <div className={styles['message-context-menu']}>
            <MenuIcon className={styles['menu-icon']} onClick={(event: any) => handleDropdownOpen(event)} />
            <ContextMenu items={contextActions} visible={Boolean(anchorEl)} handleClose={handleDropdownClose} anchorEl={anchorEl} />
          </div>
        }
      </div>
    </div>
  );
};

export default LivestreamChatMessage;
