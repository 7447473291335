/* eslint-disable class-methods-use-this */
import React, { createRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ReactComponent as MinimizeButton } from '../../../../assets/svg/minus.svg';
import { ReactComponent as SendArrow } from '../../../../assets/svg/send-arrow.svg';
import { ReactComponent as CloseButton } from '../../../../assets/svg/times.svg';
import { ReactComponent as UserFriendsIcon } from '../../../../assets/svg/user-group-solid-circle.svg';
import { RoomsSocketContext } from '../../../../contexts/RoomsSocketContextProvider';
import { PlatformRole, Room, RoomType } from '../../../../interfaces';
import MessagePacket from '../../../../interfaces/MessagePacket';
import MessageType from '../../../../interfaces/MessageType';
import ModerationAction from '../../../../interfaces/ModerationAction';
import ModerationRequest from '../../../../interfaces/ModerationRequest';
import RoomMessage from '../../../../interfaces/RoomMessage';
import SocketEvent from '../../../../interfaces/SocketEvent';
import IUser from '../../../../interfaces/IUser';
import { dataLayer } from '../../../../utils/DataLayerUtil';
import { getDisplayName } from '../../../../utils/DisplayNameUtil';
import ChatMessage from './ChatMessage/ChatMessage';

import styles from './ChatWindow.module.scss';

interface ChatWindowProps {
  title: string;
  roles: PlatformRole[];
  userInfo: IUser;
  getPreviousMessages: Function;
  getMessages: Function;
  onExitChat: Function;
  onMinimizeChat: Function;
  room: Room;
  getRoomParticipants: Function;
  getRecentUserActions: Function | null;
  getUser: Function;
}

interface ChatWindowState {
  minimized: boolean;
  messages: RoomMessage[];
  userActionsMessages: string[];
  windowIcon: string;
}

class ChatWindow extends React.Component<ChatWindowProps, ChatWindowState> {
  static contextType = RoomsSocketContext;

  static self: any;

  inputRef: React.RefObject<any>;

  state = {
    minimized: false,
    messages: [] as RoomMessage[],
    userActionsMessages: [] as string[],
    hasUnloadedMessages: true,
    windowIcon: '',
  };

  constructor(props: ChatWindowProps) {
    super(props);
    this.inputRef = createRef();
  }

  componentDidMount() {
    const { socket } = this.context;
    ChatWindow.self = this;
    socket.on(SocketEvent.RoomMessage, this.onMessageReceived);
    socket.on(SocketEvent.MessageDeleted, this.onMessageDeleted);
    socket.on(SocketEvent.UserJoinedRoom, this.onUserJoinedRoom);
    this.inputRef.current.addEventListener('keydown', this.onSubmit);

    this.loadMessages();

    this.loadParticipants();
  }

  componentWillUnmount() {
    const { socket } = this.context;
    socket.off(SocketEvent.RoomMessage, this.onMessageReceived);
    socket.off(SocketEvent.MessageDeleted, this.onMessageDeleted);
    socket.off(SocketEvent.UserJoinedRoom, this.onUserJoinedRoom);
    this.inputRef.current.removeEventListener('keydown', this.onSubmit);
  }

  async loadMessages() {
    const { getMessages, room } = this.props;
    const messages: RoomMessage[] = await getMessages(room._id);
    this.setState({ messages: messages.reverse() });
  }

  async loadParticipants() {
    const { room, getRoomParticipants } = this.props;
    const { userId } = this.context;
    let windowIcon = null;
    if (room.type === RoomType.Direct) {
      const roomParticipants = await getRoomParticipants(room._id);
      const otherParticipants = roomParticipants
        .filter((user: any) => user.userId !== userId);
      if (otherParticipants.length === 1) {
        windowIcon = otherParticipants[0].participant.picture;
      }
    }
    this.setState({ windowIcon });
  }

  async fetchUserActionInfo(userId: string) {
    const { getRecentUserActions, getUser } = ChatWindow.self.props;
    const { userActionsMessages } = ChatWindow.self.state;
    if (getRecentUserActions) {
      const response = await getRecentUserActions(userId);
      const userResponse = await getUser(userId);
      const recentUserActions = response.userActions;
      const contentTitles: string[] = recentUserActions.map((userAction: any) => userAction.content.title);
      const uniqueTitles = Array.from(new Set(contentTitles).keys());
      if (userResponse && uniqueTitles.length > 0) {
        const sentence = `${getDisplayName(userResponse.participant)} watched ${uniqueTitles.join(', ')}`;
        userActionsMessages.unshift(sentence);
      } else {
        userActionsMessages.unshift('User has not engaged with content recently.');
      }
      ChatWindow.self.setState({ userActionsMessages });
    }
  }

  onMessageReceived(receivedMessage: MessagePacket) {
    const { room } = ChatWindow.self.props;
    const { messages } = ChatWindow.self.state;
    if (room._id !== receivedMessage.roomId) {
      return;
    }
    messages.unshift({
      roomId: receivedMessage.roomId,
      userId: receivedMessage.userId,
      user: {
        displayName: receivedMessage.displayName,
        picture: receivedMessage.picture,
      },
      _id: receivedMessage.messageId,
      text: receivedMessage.text,
      createdAt: receivedMessage.createdAt,
    });
    ChatWindow.self.setState({ messages });
  }

  onMessageDeleted(deletedMessage: RoomMessage) {
    const { messages } = ChatWindow.self.state;
    ChatWindow.self.setState({ messages: messages.filter((m: RoomMessage) => m._id !== deletedMessage._id) });
  }

  onUserJoinedRoom(packet: { remoteUserId: string, roomId: string }) {
    const { room, roles } = ChatWindow.self.props;
    if (room._id !== packet.roomId) {
      return;
    }
    if (roles.includes(PlatformRole.SalesRep)) {
      ChatWindow.self.fetchUserActionInfo(packet.remoteUserId);
    }
  }

  onSubmit(event: React.KeyboardEvent) {
    if (event.key === 'Enter' || event.code === 'NumpadEnter') {
      ChatWindow.self.sendMessage();
    }
  }

  onContextActionClicked(action: ModerationAction, moderationTargetId: string) {
    const { socket } = ChatWindow.self.context;
    const { userInfo } = ChatWindow.self.props;

    if (action === ModerationAction.SeeInterests) {
      ChatWindow.self.fetchUserActionInfo(moderationTargetId);
      return;
    }
    const moderationRequest: ModerationRequest = { action, moderationTargetId, sender: userInfo.userId };
    switch (action) {
      case ModerationAction.DeleteMessage:
        socket.emit(SocketEvent.DeleteMessage, moderationRequest);
        break;
      default:
        socket.emit(SocketEvent.ControlAccess, moderationRequest);
        break;
    }
  }

  async loadMore() {
    const { getPreviousMessages, room } = ChatWindow.self.props;
    const { messages } = ChatWindow.self.state;
    const newMessages = await getPreviousMessages(room._id, messages[messages.length - 1]._id);
    const stateUpdate = messages.concat(newMessages.reverse());
    ChatWindow.self.setState({ messages: stateUpdate, hasUnloadedMessages: newMessages.length !== 0 });
  }

  sendMessage() {
    if (this.inputRef.current.value.trim() === '' ||
      this.inputRef.current.value.length > 1000) {
      return;
    }
    const { room, userInfo } = this.props;
    const { socket } = this.context;
    const packet: MessagePacket = {
      type: MessageType.Message,
      userId: userInfo.userId,
      roomId: room._id,
      text: this.inputRef.current.value.trim(),
      displayName: userInfo.displayName ?? userInfo.userId,
      picture: userInfo.picture,
    };
    socket.emit(SocketEvent.SendMessage, packet);
    this.inputRef.current.value = '';
    dataLayer.push({ event: 'message_send', px_room_id: room._id });
  }

  render() {
    const {
      title, userInfo, onExitChat, onMinimizeChat, roles, room,
    } = this.props;
    const {
      messages, hasUnloadedMessages, windowIcon, userActionsMessages,
    } = this.state;
    return (
      <div className={styles['chat-window']}>
        <div className={styles.header}>
          {
            !windowIcon
              ?
              <UserFriendsIcon className={styles['user-friends-icon']} />
              :
              <img className={styles.channelIcon} src={windowIcon} />
          }
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.buttons}>
            <MinimizeButton className={styles['action-button']} onClick={() => onMinimizeChat()} />
            {!(room.type !== RoomType.Direct && room.capabilities.video) &&
              <CloseButton className={styles['action-button']} onClick={() => onExitChat()} />}
          </div>
        </div>
        {
          userActionsMessages &&
          <div className={styles['user-actions']}>
            {userActionsMessages.map((userAction: string, i: number) => (
              <div key={`${userAction}.${i}`} className={styles['user-action']}>
                <div className={styles['user-action-text']}>{userAction}</div>
                <CloseButton
                  className={styles['user-action-dismiss']}
                  onClick={() => {
                    this.setState({
                      userActionsMessages:
                        userActionsMessages.filter((message) => message !== userAction),
                    });
                  }}
                />
              </div>
            ))}
          </div>
        }
        <div className={styles.messages} id="messagesDiv">
          <InfiniteScroll
            dataLength={messages.length}
            next={this.loadMore}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            inverse
            hasMore={hasUnloadedMessages}
            loader=""
            scrollableTarget="messagesDiv"
          >
            {
              messages.map((message) => (
                <ChatMessage
                  key={message.createdAt}
                  message={message}
                  roles={roles}
                  onContextActionClicked={this.onContextActionClicked}
                  isLocalSender={message.userId === userInfo.userId}
                />
              ))
            }
          </InfiniteScroll>
        </div>
        <div className={styles['input-container']}>
          <input ref={this.inputRef} className={styles['chat-input']} placeholder="Start Typing..." />
          <SendArrow className={styles['send-arrow']} onClick={() => this.sendMessage()} />
        </div>
      </div>
    );
  }
}

export default ChatWindow;
