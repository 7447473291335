/* eslint-disable class-methods-use-this */
import React, { createRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { RoomsSocketContext } from '../../../../contexts/RoomsSocketContextProvider';
import { ReactComponent as SendArrow } from '../../../../assets/svg/send-arrow.svg';
import {
  MessagePacket, ModerationAction, ModerationRequest, PlatformRole, Room, RoomMessage, SocketEvent, IUser,
} from '../../../../interfaces';
import MessageType from '../../../../interfaces/MessageType';
import LivestreamChatMessage from '../LivestreamChatMessage/LivestreamChatMessage';

import styles from './LivestreamChat.module.scss';

interface LivestreamChatProps {
  roles: PlatformRole[];
  userInfo: IUser;
  getPreviousMessages: Function;
  getMessages: Function;
  room: Room;
  isQuestionsOnly: boolean;
}

interface ChatWindowState {
  minimized: boolean;
  messages: RoomMessage[];
}

class LivestreamChat extends React.Component<LivestreamChatProps, ChatWindowState> {
  static contextType = RoomsSocketContext;

  static self: any;

  inputRef: React.RefObject<any>;

  state = {
    minimized: false,
    messages: [] as RoomMessage[],
    hasUnloadedMessages: true,
  };

  constructor(props: LivestreamChatProps) {
    super(props);
    this.inputRef = createRef();
  }

  componentDidMount() {
    const { socket } = this.context;
    LivestreamChat.self = this;
    socket.on(SocketEvent.RoomMessage, this.onMessageReceived);
    socket.on(SocketEvent.MessageDeleted, this.onMessageDeleted);
    this.inputRef.current.addEventListener('keydown', this.onSubmit);
    this.loadMessages();
  }

  componentWillUnmount() {
    const { socket } = this.context;
    socket.off(SocketEvent.RoomMessage, this.onMessageReceived);
    socket.off(SocketEvent.MessageDeleted, this.onMessageDeleted);
    this.inputRef.current.removeEventListener('keydown', this.onSubmit);
  }

  async loadMessages() {
    const { getMessages, room } = this.props;
    const messages: RoomMessage[] = await getMessages(room._id);
    this.setState({ messages: messages.reverse() });
  }

  onMessageReceived(receivedMessage: MessagePacket) {
    const { room } = LivestreamChat.self.props;
    const { messages } = LivestreamChat.self.state;
    if (room._id !== receivedMessage.roomId) {
      return;
    }
    messages.unshift({
      roomId: receivedMessage.roomId,
      userId: receivedMessage.userId,
      user: {
        displayName: receivedMessage.displayName,
        picture: receivedMessage.picture,
      },
      _id: receivedMessage.messageId,
      text: receivedMessage.text,
      createdAt: receivedMessage.createdAt,
    });
    LivestreamChat.self.setState({ messages });
  }

  onMessageDeleted(deletedMessage: RoomMessage) {
    const { messages } = LivestreamChat.self.state;
    LivestreamChat.self.setState({ messages: messages.filter((m: RoomMessage) => m._id !== deletedMessage._id) });
  }

  onSubmit(event: React.KeyboardEvent) {
    if (event.key === 'Enter' || event.code === 'NumpadEnter') {
      LivestreamChat.self.sendMessage();
    }
  }

  onContextActionClicked(action: ModerationAction, moderationTargetId: string) {
    const { socket } = LivestreamChat.self.context;
    const { userInfo } = LivestreamChat.self.props;

    if (action === ModerationAction.SeeInterests) {
      LivestreamChat.self.fetchUserActionInfo(moderationTargetId);
      return;
    }
    const moderationRequest: ModerationRequest = { action, moderationTargetId, sender: userInfo.userId };
    switch (action) {
      case ModerationAction.DeleteMessage:
        socket.emit(SocketEvent.DeleteMessage, moderationRequest);
        break;
      default:
        socket.emit(SocketEvent.ControlAccess, moderationRequest);
        break;
    }
  }

  async loadMore() {
    const { getPreviousMessages, room } = LivestreamChat.self.props;
    const { messages } = LivestreamChat.self.state;
    const newMessages = await getPreviousMessages(room._id, messages[messages.length - 1]._id);
    const stateUpdate = messages.concat(newMessages.reverse());
    LivestreamChat.self.setState({ messages: stateUpdate, hasUnloadedMessages: newMessages.length !== 0 });
  }

  sendMessage() {
    const { room, userInfo, isQuestionsOnly } = this.props;
    const { socket } = this.context;
    if (this.inputRef.current.value === '' ||
      this.inputRef.current.value.length > 1000 ||
      (isQuestionsOnly && !this.inputRef.current.value.includes('?'))) {
      return;
    }

    const packet: MessagePacket = {
      type: MessageType.Message,
      userId: userInfo.userId,
      roomId: room._id,
      text: this.inputRef.current.value,
      displayName: userInfo.displayName ?? userInfo.userId,
      picture: userInfo.picture,
    };
    socket.emit(SocketEvent.SendMessage, packet);
    this.inputRef.current.value = '';
  }

  render() {
    const { roles, isQuestionsOnly } = this.props;
    const { messages, hasUnloadedMessages } = this.state;
    return (
      <div className={styles['livestream-chat']}>
        {
          isQuestionsOnly &&
          <div className={styles['questions-only']}>
            Only questions are accepted in this chat box please do not have conversations here!
          </div>
        }
        <div className={styles.messages} id="messagesDiv">
          <InfiniteScroll
            dataLength={messages.length}
            next={this.loadMore}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            inverse
            hasMore={hasUnloadedMessages}
            loader=""
            scrollableTarget="messagesDiv"
          >
            {
              messages.map((message) => (
                <LivestreamChatMessage
                  key={message.createdAt}
                  message={message}
                  roles={roles}
                  onContextActionClicked={this.onContextActionClicked}
                />
              ))
            }
          </InfiniteScroll>
        </div>
        <div className={styles['input-container']}>
          <input ref={this.inputRef} className={styles['chat-input']} placeholder="Start typing here..." onSubmit={(event: any) => this.onSubmit(event)} />
          <div className={styles['send-arrow-container']}><SendArrow className={styles['send-arrow']} onClick={() => this.sendMessage()} /></div>
        </div>
      </div>
    );
  }
}

export default LivestreamChat;
