import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import useAccessMetaverse from 'src/features/Visitor/hooks/useAccessMetaverse';

import avatar from '../../../../../assets/images/profile-pic.png';
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/ellipsis-h.svg';
import ContextMenu, { ContextMenuItem } from '../../../../../components/ContextMenu/ContextMenu';
import { PlatformRole } from '../../../../../interfaces';
import ModerationAction from '../../../../../interfaces/ModerationAction';
import RoomMessage from '../../../../../interfaces/RoomMessage';
import { getDisplayName } from '../../../../../utils/DisplayNameUtil';
import { sanitizeText, urlParse } from '../../../../../utils/StringUtils';

import styles from './ChatMessage.module.scss';

interface ChatMessageProps {
  roles: PlatformRole[];
  isLocalSender: boolean;
  onContextActionClicked: Function | undefined;
  message: RoomMessage;
}

const ChatMessage = (
  {
    roles, isLocalSender, onContextActionClicked, message,
  }: ChatMessageProps,
) => {
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [contextActions, setContextActions] = useState<ContextMenuItem[]>([]);

  const { ability } = useAccessMetaverse();
  const hasModerationAbility = ability.can(Action.Manage, Subject.ModerationAction);

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    let actions: any[] = []; // Add  default actions here
    const salesRepActions: ContextMenuItem[] = [
      {
        label: 'See Interests',
        onClick: () => {
          if (onContextActionClicked) {
            onContextActionClicked(ModerationAction.SeeInterests, message.userId);
          }
          handleDropdownClose();
        },
      },
    ];
    const adminContextActions: ContextMenuItem[] = [
      /* {
        label: 'Ban User',
        onClick: () => {
          if (onContextActionClicked) {
            onContextActionClicked(ModerationAction.BanUser, message.userId);
          }
          handleDropdownClose();
        },
      }, */
      {
        label: 'Delete',
        onClick: () => {
          if (onContextActionClicked) {
            onContextActionClicked(ModerationAction.DeleteMessage, message._id);
          }
          handleDropdownClose();
        },
      },
    ];
    if (hasModerationAbility) {
      actions = actions.concat(adminContextActions);
    }
    if (roles.includes(PlatformRole.SalesRep)) {
      actions = actions.concat(salesRepActions);
    }
    setContextActions(actions);
  }, [roles]);

  const isAttendee = contextActions.length === 0;

  return (
    <>
      {
        isLocalSender ?
          <div className={clsx(styles['chat-message-bubble'], styles['sent-by-user'], { [styles.attendee]: isAttendee })}>
            <div className={styles['chat-data']}>
              {
                onContextActionClicked && contextActions.length > 0 &&
                <div className={styles['message-context-menu']}>
                  <MenuIcon className={styles['menu-icon']} onClick={(event) => handleDropdownOpen(event)} />
                  <ContextMenu items={contextActions} visible={Boolean(anchorEl)} handleClose={handleDropdownClose} anchorEl={anchorEl} />
                </div>
              }
              <div className={clsx(styles['message-text'], styles['sent-by-user'])} dangerouslySetInnerHTML={{ __html: urlParse(sanitizeText(message.text)) }} />
              <img className={styles.avatar} src={message?.user?.picture || avatar} alt="" />
            </div>
          </div> :
          <div className={styles['chat-message-bubble']}>
            <div className={styles.username}>
              <img className={styles.avatar} src={message?.user?.picture || avatar} alt="" />
              <div className={styles['username-text']}>
                {`${getDisplayName(message.user)}:`}
              </div>
            </div>
            <div className={styles['chat-data']}>
              <div className={styles['message-text']} dangerouslySetInnerHTML={{ __html: urlParse(sanitizeText(message.text)) }} />
              {
                contextActions.length > 0 && onContextActionClicked &&
                <div className={styles['message-context-menu']}>
                  <MenuIcon className={styles['menu-icon']} onClick={(event) => handleDropdownOpen(event)} />
                  <ContextMenu items={contextActions} visible={Boolean(anchorEl)} handleClose={handleDropdownClose} anchorEl={anchorEl} />
                </div>
              }
            </div>
          </div>
      }
    </>
  );
};

export default ChatMessage;
