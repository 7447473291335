import { AxiosResponse } from 'axios';
import { IMetaverseCustomFields } from '../interfaces/IMetaverseCustomFields';
import { PlatformRole } from '../interfaces';
import { MetaverseResponse, IMetaverse } from '../interfaces/IMetaverse';
import IMetaverseVisitor from '../interfaces/IMetaverseVisitor';
import IUser from '../interfaces/IUser';
import { dataLayer } from '../utils/DataLayerUtil';
import { ICustomField } from '../interfaces/ICustomField';
import api from './api';

export async function getUser(userId: string) {
  const response = await api.get(`/api/v2/users/${userId}`);
  return response.data;
}

export async function updateUser(userId: string, user: IUser) {
  const response = await api.patch(`/api/v2/users/${userId}`, user);
  dataLayer.push({ event: 'user_update' });
  return response.data;
}

export async function saveUserProfilePic(
  userId: string, image: string,
): Promise<AxiosResponse<any>> {
  const formData = new FormData();
  formData.append('picture', image);
  const response = await api.put(`/api/v2/users/${userId}/picture`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  dataLayer.push({ event: 'user_profile_picture_upload' });
  return response.data;
}

export async function postUserAction(metaverseId: string, userId: string, action: string, contentId?: string) {
  const response = await api.post('/api/v2/metaverses/userAction', {
    userId,
    metaverseId,
    action,
    contentId,
  });
  return response.data;
}

export async function getUserActions(metaverseId: string, userId: string) {
  const response = await api.get('/api/v2/metaverses/userActions', { params: { metaverseId, userId } });
  return response.data;
}

export async function resendEmailVerification(userId: string) {
  const clientId = window.env.REACT_APP_AUTH0_CLIENT_ID || '';
  const res = await api.post('/api/v2/auth/verify/send', { userId, clientId });
  dataLayer.push({ event: 'email_verification_send' });
  return res.data;
}

export async function sendEmailInvites(emailAddresses: string[], roles: PlatformRole[], metaverseId: string): Promise<any> {
  const res = await api.post('/api/v2/metaverses/send-invite', { emailAddresses, roles, metaverseId });
  return res.data;
}

export async function acceptEmailInvite(code: string) : Promise<any> {
  const res = await api.post('/api/v2/metaverses/activate-invite', { code });
  return res.data;
}

export async function getMetaverses(roles: string[], currentMetaverses: boolean) {
  const { data } = await api.get<MetaverseResponse[]>(`/api/v2/metaverses?roles=${roles}&currentMetaverses=${currentMetaverses}`);
  return data;
}

export async function getMetaversesByURL(url: string, populate = true): Promise<AxiosResponse<any>> {
  const response = await api.get('/api/v2/metaverses/url', { params: { url } });
  const metaverse = response.data;
  if (!populate) return metaverse;
  return metaverse;
}

export async function getMetaverse(metaverseId: string) {
  const { data } = await api.get<MetaverseResponse>(`/api/v2/metaverses/${metaverseId}`);
  return data;
}

export async function addUserToMetaverse(userId: string, metaverseId: string) {
  const res = await api.post<IMetaverseVisitor>(`/api/v2/metaverses/${metaverseId}/participants`, { userId });
  return res.data;
}

export async function postMetaverse(metaverse: IMetaverse) {
  const res = await api.post('/api/v2/metaverses', metaverse);
  dataLayer.push({ event: 'event_create', px_event_id: res.data._id });
  return res.data;
}

export async function patchMetaverse(metaverseId: string, update: Partial<IMetaverse>, populate = true) {
  const response = await api.patch(`/api/v2/metaverses/${metaverseId}`, update);
  const metaverse = response.data;
  if (!populate) return metaverse;
  return response.data;
}

export async function publishMetaverse(metaverseId: string) {
  const data = patchMetaverse(metaverseId, { published: true });
  dataLayer.push({ event: 'event_publish', px_event_id: metaverseId });
  return data;
}

export async function unpublishMetaverse(metaverseId: string) {
  const data = patchMetaverse(metaverseId, { published: false });
  dataLayer.push({ event: 'event_unpublish', px_event_id: metaverseId });
  return data;
}

export async function deleteMetaverse(metaverseId: string) {
  const response = await api.delete(`/api/v2/metaverses/${metaverseId}`);
  dataLayer.push({ event: 'event_delete', px_event_id: metaverseId });
  return response.data;
}

export async function postMetaverseLogo(image: string): Promise<AxiosResponse<any>> {
  const formData = new FormData();
  formData.append('picture', image);
  const response = await api.post('/api/v2/metaverses/logo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  dataLayer.push({ event: 'event_logo_upload' });
  return response.data;
}

export async function getMetaverseVisitorsPaginated(metaverseId: string, skip: number, limit: number, useDbQuery: boolean) {
  const response = await api.get(`/api/v2/metaverses/${metaverseId}/participants`, { params: { skip, limit, useDb: useDbQuery } });
  return response.data;
}

export async function getMetaverseVisitorDetailsPaginated(metaverseId: string, skip: number, limit: number, sortBy: string) {
  const response = await api.get(`/api/v2/metaverses/${metaverseId}/participant-details`, { params: { skip, limit, sortBy } });
  return response.data;
}

export async function getMetaverseVisitorDetailsPaginatedSearch(
  metaverseId: string, skip: number, limit: number, search: string,
) {
  const response = await api.get(`/api/v2/metaverses/${metaverseId}/participant-details`, { params: { skip, limit, search } });
  return response.data;
}

export async function getMetaverseVisitorsPaginatedSearch(
  metaverseId: string, skip: number, limit: number, search: string,
) {
  const response = await api.get(`/api/v2/metaverses/${metaverseId}/participants`, { params: { skip, limit, search } });
  return response.data;
}

export async function exportMetaverseVisitors(metaverseId: string) {
  const response = await api.get(`/api/v2/metaverses/${metaverseId}/participant-export`);
  return response.data;
}

export async function getMetaverseVisitor(metaverseId: string, userId: string) {
  const response = await api.get(`/api/v2/metaverses/${metaverseId}/participants/${userId}`);
  return response.data;
}

export async function updateMetaverseVisitorAccess(metaverseId: string, userId: string, status: string) {
  const response = await api.patch(`/api/v2/metaverses/${metaverseId}/participants/${userId}/accesscontrol`, { status });
  dataLayer.push({ event: 'participant_access_update', px_event_id: metaverseId });
  return response.data;
}

export async function getUrlAvailability(url: string) {
  const response = await api.get(`/api/v2/metaverses/url-availability?url=${url}`);
  return response.data;
}

// TODO: Rework this once new agenda endpoints is added
// export async function postToPersonalAgenda(metaverseId: string, contentId: string[]) {
//   const response = await api.post(`/api/v2/metaverses/personalAgenda?metaverseId=${metaverseId}`, contentId);
//   return response.data;
// }

// TODO: Rework this once new agenda endpoints is added
// export async function deleteFromPersonalAgenda(metaverseId: string, contentId: string[]) {
//   const response = await api.patch(`/api/v2/metaverses/personalAgenda?metaverseId=${metaverseId}`, contentId);
//   return response.data;
// }

export async function getCustomMetaverseFields(metaverseId: string) {
  const response = await api.get<IMetaverseCustomFields>(`/api/v2/metaverses/${metaverseId}/customfields`);
  return response.data;
}

export async function patchCustomMetaverseFields(metaverseId: string, customFields: ICustomField[], defaultProfilePicture: string, permitEmailUseText: string) {
  const response = await api.patch(`/api/v2/metaverses/${metaverseId}/customfields`, { defaultProfilePicture, fields: customFields, permitEmailUseText });
  return response.data;
}

export async function patchMetaverseUserDetails(metaverseId: string, fields: any): Promise<any> {
  const response = await api.patch(`/api/v2/metaverses/${metaverseId}/userdetails`, fields);
  return response.data;
}

export async function getAllowedEmailDomains(metaverseId: string) {
  const { data }: { data: any[] } = await api.get(`/api/v2/metaverses/${metaverseId}/domains`);
  return data;
}

export async function putAllowedEmailDomain(metaverseId: string, domain: string): Promise<any> {
  const { data }: { data: any[] } = await api.put(`/api/v2/metaverses/${metaverseId}/domains/${domain}`);
  return data;
}

export async function deleteAllowedEmailDomain(metaverseId: string, domain: string): Promise<any> {
  const { data }: { data: any[] } = await api.delete(`/api/v2/metaverses/${metaverseId}/domains/${domain}`);
  return data;
}
