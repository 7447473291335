import WideButton from '../WideButton/WideButton';

import icon from '../../../../../assets/images/login/wallet.png';
import { useAuthModalContext } from '../AuthModalContextProvider';

type Props = {
  className?: string;
};

export default function WalletLoginWideButton({
  className,
}: Props) {
  const { setOpenWalletModal } = useAuthModalContext();
  return (
    <WideButton
      classes={{ button: className }}
      icon={icon}
      onClick={() => setOpenWalletModal(true)}
    >
      Crypto Wallets
    </WideButton>
  );
}
