import axios from 'axios';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useUpdateMe } from 'src/queries/me';
import defaultAvatar from '../../../../assets/images/profile-pic.png';
import PlatformSpinner from '../../../../components/PlatformSpinner/PlatformSpinner';
import { ICustomField } from '../../../../interfaces/ICustomField';
import { IMetaverseCustomFields } from '../../../../interfaces/IMetaverseCustomFields';
import { selectMetaverseVisitor, setMetaverseVisitorField } from '../../../../redux/metaverseVisitor';
import { setUserProfile } from '../../../../redux/userProfile';
import {
  getCustomMetaverseFields,
  patchMetaverseUserDetails, saveUserProfilePic,
} from '../../../../services/MetaverseService';
import { compressImage, readURL } from '../../../../utils/FileUtil';
import NFTSelectionModal from '../NFTSelectionModal/NFTSelectionModal';
import VisitorInput from '../VisitorInput/VisitorInput';
import VisitorModal from '../VisitorModal/VisitorModal';
import VisitorModalActions from '../VisitorModalActions/VisitorModalActions';
import VisitorModalContent from '../VisitorModalContent/VisitorModalContent';

import useAccessMetaverse from '../../hooks/useAccessMetaverse';
import Button from '../Button';
import styles from './ProfileModal.module.scss';

export type ProfileModalInterface = {
  completeUserProfile: Function;
  open: Function;
  close: Function;
};

const ProfileModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const userProfile = useSelector(
    (state: any) => state.userProfile,
    shallowEqual,
  );
  const { metaverse: metaverseDetails } = useAccessMetaverse();
  const metaverseVisitor = useSelector(selectMetaverseVisitor, shallowEqual);
  const [showRequiredFields, setShowRequiredFields] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [customFields, setCustomFields] = useState<IMetaverseCustomFields>();
  const [showSpinner, setShowSpinner] = useState(false);
  const [openNFTModal, setOpenNFTModal] = useState(false);

  const { mutate: updateMe } = useUpdateMe();

  useEffect(() => {
    fetchMetaverseFields();
  }, [metaverseDetails]);

  async function fetchMetaverseFields() {
    if (!metaverseDetails?._id) return;
    const fieldData = await getCustomMetaverseFields(metaverseDetails._id);
    setCustomFields(fieldData);
  }

  async function completeUserProfile() {
    try {
      const hasRequiredCustomFields = (customFields?.fields || [])
        .filter((field) => field.required)
        .every(
          (requiredField: any) => metaverseVisitor.fields[requiredField.name],
        );
      if (!userProfile.displayName || !hasRequiredCustomFields) {
        setShowRequiredFields(true);
        return;
      }
      updateMe({ ...userProfile, newProfile: false });
      if (metaverseVisitor?.fields) {
        await patchMetaverseUserDetails(metaverseDetails?._id ?? '', metaverseVisitor.fields);
      }
      close();
    } catch (e) { }
  }

  async function uploadUserImage(image: string) {
    try {
      const response: any = await saveUserProfilePic(userProfile.userId, image);
      dispatch(setUserProfile({ ...userProfile, picture: response.location }));
    } catch (e: any) {
    } finally {
      setShowSpinner(false);
    }
  }

  function _onClose() {
    const { onClose } = props as any;
    if (onClose) {
      onClose();
    }
  }

  const onNFTSelected = async (selectedNFT: string) => {
    const file = await axios
      .get(selectedNFT, { responseType: 'blob' })
      .then((res) => new File([res.data], 'nft', { type: res.headers['content-type'] }));

    const updatedPicture = file.type === 'image/gif' ? file : await compressImage(file);
    const data: any = await saveUserProfilePic(userProfile._id, updatedPicture as any);
    updateMe({
      ...userProfile,
      picture: data.location,
      newProfile: false,
    });
    setOpenNFTModal(false);
  };

  function open() {
    setProfileModalVisible(true);
  }

  function close() {
    if (!userProfile.displayName) {
      setShowRequiredFields(true);
      return;
    }
    _onClose();
    setProfileModalVisible(false);
  }

  useImperativeHandle(ref, () => ({
    open,
    close,
    completeUserProfile,
  }));

  const customFieldElements = (customFields?.fields || [])
    .map((field: ICustomField) => (
      <VisitorInput
        key={field.name}
        title={field.name + (field.required ? '*' : '')}
        placeholder="Click to type..."
        value={String(metaverseVisitor.fields?.[field.name])}
        onValueChange={(value: string) => dispatch(setMetaverseVisitorField({ fieldName: field.name, value }))}
        showRequired={showRequiredFields && field.required}
      />
    ));

  const picture = userProfile.picture
    ? userProfile.picture
    : customFields?.customProfilePicture || defaultAvatar;

  return (
    <VisitorModal
      className={styles.modal}
      open={profileModalVisible}
      onClose={close}
    >
      <VisitorModalContent
        className={styles.content}
      >
        <div className={styles.header}>
          Profile
        </div>
        <div className={styles.description}>
          Your profile photo and display name will be visible to others in the space.
        </div>
        <div className={styles.pictureLabel}>
          PROFILE PHOTO
        </div>
        <div className={styles.uploadContainer}>
          <div className={styles.uploadRendered}>
            <PlatformSpinner visible={showSpinner} />
            <div className={styles.dashedBorder}>
              <img className={styles.picture} src={picture} alt="Profile" />
            </div>
          </div>
          <div className={styles.uploadActionsContainer}>
            <div className={styles.uploadActions}>
              <label
                className={styles.uploadButton}
                htmlFor="file-upload-profile-modal"
              >
                Upload
              </label>
              <Button
                className={styles.selectnft}
                color="secondary"
                size="sm"
                onClick={() => setOpenNFTModal(true)}
              >
                Select NFT
              </Button>
            </div>
            <div className={styles.uploadDescription}>
              Profile uploads cannot exceed 2MB.
            </div>
            <input
              className={styles.fileInput}
              id="file-upload-profile-modal"
              type="file"
              accept="image/*"
              onChange={(e) => readURL(
                e.target,
                (file: any, fileType: string) => uploadUserImage(file),
                () => setShowSpinner(true),
              )}
            />
          </div>
        </div>
        <div>
          <VisitorInput
            title="Display Name*"
            placeholder="Click to type..."
            maxLength={50}
            value={userProfile.displayName}
            onValueChange={(value: string) => dispatch(setUserProfile({ ...userProfile, displayName: value }))}
            showRequired={showRequiredFields}
          />
          {customFieldElements}
        </div>
      </VisitorModalContent>
      <VisitorModalActions className={styles.actions}>
        <Button color="tertiary" onClick={close}>
          Cancel
        </Button>
        <Button color="primary" onClick={completeUserProfile}>
          Save
        </Button>
      </VisitorModalActions>
      <NFTSelectionModal
        open={openNFTModal}
        onNFTSelected={onNFTSelected}
        onClose={() => setOpenNFTModal(false)}
      />
    </VisitorModal>
  );
});

export default ProfileModal;
